<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>数字乡村</h1>
      <p style="text-indent: 2em">
        数字乡村是伴随网络化、信息化和数字化在农业农村经济社会发展中的应用，以及农民现代信息技能的提高而内生的农业农村现代化发展和转型进程，既是乡村振兴的战略方向，也是建设数字中国的重要内容。
      </p>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <h2 class="title">功能场景</h2>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <!-- <div class="detail" @click="go(item.id)">查看详情</div> -->
        </van-tab>
      </van-tabs>
    </div>
    <h2 class="title">更多场景</h2>
    <div class="scene">
      <van-tabs
        v-model="active1"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list1" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <!-- <div class="detail" @click="go(item.id)">查看详情</div> -->
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/image/ruralImg/组 223.png"),
      active: 0,
      active1: 0,
      list: [
        {
          id: 0,
          name: "智慧农业",
          img: require("../../assets/assets/ruralImg/img2.png"),
          text: "通过将物联网、大数据、云计算等技术应用到传统农业中，实现了对农业农田项目全周期的可追溯管理，通过农业远程诊断、远程控制等智能管理，极大节省了人力成本，提高了农田管理效率。",
        },
        {
          id: 1,
          name: "智慧党建",
          img: require("../../assets/assets/ruralImg/img3.png"),
          text: "以云计算、大数据、互联网为基础，建设全覆盖、全过程、全维度的党建信息化、智慧化的平台， 实现线上线下相互融合、相互促进的党建工作新格局。",
        },
        {
          id: 2,
          name: "智慧村务",
          img: require("../../assets/assets/ruralImg/img4.png"),
          text: "智慧村务旨在打破条块分割局面，整合民政、计生、人社等延伸到农村的政务系统和其他各类农村信息资源，实现农村信息资源共享。",
        },
        {
          id: 3,
          name: "精准扶贫",
          img: require("../../assets/assets/ruralImg/img5.png"),
          text: "针对不同贫困区域环境、不同贫困农户状况，运用科学有效程序对扶贫对象实施精确识别、精准帮扶、精确管理的治贫方式。",
        },
      ],
      list1: [
        {
          id: 0,
          name: "便民服务",
          img: require("../../assets/assets/ruralImg/img6.png"),
          text: "村民可在手机上进行村情上报、村民招聘就业、家宴预约、乡村咨询等，让村民办事更方便。",
        },
        {
          id: 1,
          name: "智慧文旅",
          img: require("../../assets/assets/ruralImg/img7.png"),
          text: "利用云计算、物联网、移动通信等多种先进技术，构建景区智慧文旅体系，提高文旅企业的综合管理和运营能力，创建优质的旅游生态环境，提升旅游的服务品质。",
        },
        {
          id: 2,
          name: "信息发布",
          img: require("../../assets/assets/ruralImg/img8.png"),
          text: "采用LED大屏，可以实施实时查看各类农业信息、村情上报、道路状况等信息。",
        },
        {
          id: 3,
          name: "监督监管",
          img: require("../../assets/assets/ruralImg/img9.png"),
          text: "履责有载体、监督有平台、建言有渠道、宣传有引导，让党内“一线”监督在基层落地生根。",
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/cityDetail",
        query: {
          cityId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/ruralImg/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 200px;
      background: url("../../assets/image/ruralImg/组 223.png") no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
    .classification {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      text-align: center;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
